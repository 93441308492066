<template>
  <div class="reports-main absolute shadow z-30 bottom-5 top-5 left-5 bg-white grid grid-cols-2 pr-6 pl-6 pt-5 grid-rows-layout reports ml-5 rounded-xl" style="width: 444px; left: 68px">
    <!-- Header (Отчеты/Мониторинг) -->
    <div class="w-full flex">
      <!-- Заголовок Отчеты -->
      <span class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro">
        {{ $t('reports') }}
      </span>
      <!-- Заголовок Мониторинг -->
      <div class="flex items-center ml-8 cursor-pointer" @click="$emit('to-monitoring')">
        <MapPointIcon />
        <span class="col-start-1 col-end-2 font-semibold text-darkblue text-l font-SourceSansPro ml-2">
          {{ $t('monitoring') }}
        </span>
      </div>
    </div>
    <!-- Кнопка закрыть -->
    <div class="flex justify-end col-start-2 items-center">
      <i class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold" @click="handleClose"></i>
    </div>
    <!-- Настройки отчета / Setting report - Container -->
    <div class="flex flex-col col-start-1 col-end-3">
      <!-- Выбор шаблона / Template select -->
      <div class="w-full flex items-center mt-8">
        <span class="blue-text font-SourceSansPro text-sm font-semibold mr-2 mt-2">
          {{ $t('reports.select-template') }}
        </span>
        <!-- Селект / Select -->
        <el-select class="mt-3 flex-1" ref="templatesSelect" v-model="reportForm.tableType" @change="tableTypeChange">
          <!-- Добавить новый шаблон / Add new tamplate -->
          <div
            class="flex justify-between items-center px-5 cursor-pointer"
            style="height: 34px; line-height: 34px"
            @click="
              $refs['templatesSelect'].blur()
              $emit('create-reporttemplate')
            "
          >
            <span class="blue-text font-SourceSansPro font-semibold" style="font-size: 13px">
              {{ $t('reports.add-new-template') }}
            </span>
            <AddIcon />
          </div>
          <!-- Список шаблонов / List template -->
          <el-option v-for="(template, index) in reportTemplates" :key="index" :label="template.name" :value="template.id">
            <template v-if="shouldShowOption(template)">
              <div class="flex justify-between items-center">
                <span class="font-bold text-headerText" :class="template.id === reportForm.tableType ? 'text-headerText' : 'blue-text'">
                  {{ template.name }}
                </span>
                <span>
                  <!-- Редактирование шаблона / Edit template -->
                  <span
                    v-if="!template.is_default"
                    class="mr-4"
                    @click.stop="
                      $refs['templatesSelect'].blur()
                      $emit('update-reporttemplate', template)
                    "
                  >
                    <EditIcon />
                  </span>
                  <!-- Удаление шаблона / Delete template -->
                  <span v-if="!template.is_default" @click.stop="openDialogDeleteTemplate(template)">
                    <!-- <span @click.stop="centerDialogVisible = true"> -->
                    <CancelBigIcon />
                  </span>
                </span>
              </div>
            </template>
          </el-option>
        </el-select>
      </div>

      <!-- Выбор радио кнопки / object group or from monitoring select -->
      <div class="w-full blue-text flex items-center mt-6 bg-select-object select-object-container">
        <div class="flex justify-between w-16 mr-4">
          <!-- Обьект / Object -->
          <skif-radio v-model="reportForm.object" reverse @change="objectChange" name="object" label="base">
            {{ $t('reports.object') }}
          </skif-radio>
        </div>
        <div class="flex justify-between w-32">
          <!-- Группа обьектов / Group object -->
          <skif-radio v-model="reportForm.object" @change="objectChange" reverse name="object" label="group">
            {{ $t('reports.group-object') }}
          </skif-radio>
        </div>
        <div v-if="isFilterExist" class="flex justify-between ml-4 w-32">
          <!-- Из мониторинга / From monitoring -->
          <skif-radio v-model="reportForm.object" @change="objectChange" name="object" label="monitoring" class="flex flex-row-reverse">
            {{ $t('reports.from_monitoring') }}
          </skif-radio>
        </div>
      </div>

      <!-- Выбор обьекта (select) / Select object -->
      <div class="flex flex-row relative mt-4" v-if="reportForm.object !== 'monitoring'">
        <span class="blue-text font-SourceSansPro text-sm font-semibold mt-4 mr-2"> {{ $t('reports.object') }} : </span>
        <div class="flex items-center flex-1">
          <el-select v-model="reportForm.unitsId" :default-first-option="true" :fit-input-width="true" :class="{ 'input-error': isUnitOrUnitsgroupMissing }" class="mt-3 w-full" filterable="filterable" ref="unitsSelect" @change="unitsNameChange">
            <!-- Если выбран обьект / If object selected -->
            <template v-if="reportForm.object === 'base'">
              <el-option class="text-lightblue" v-for="unit in sortedUnits" :key="unit.id" :label="unit.name" :value="unit.id">
                <div class="flex justify-between">
                  <span class="truncate" style="width: 220px">
                    {{ unit.name }}
                  </span>
                  <span
                    @click="
                      $emit('update-unit', {
                        unitId: unit.id,
                        fromModule: 'reports'
                      })
                    "
                  >
                    <EditIcon />
                  </span>
                </div>
              </el-option>
            </template>
            <!-- Выбрана группа обьектов / Select group object -->
            <template v-else>
              <el-option class="text-lightblue" v-for="group in unitGroups" :key="group.id" :label="group.name" :value="group.id">
                <div class="flex justify-between">
                  <!-- Имя группы / Длина группы -->
                  <span class="group-name">{{ group.name }} ({{ group.units.length }})</span>
                  <span
                    @click="
                      $emit('update-unitsgroup', {
                        unitsgroup: group,
                        isUnits: false
                      })
                    "
                  >
                    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.414.586a2 2 0 010 2.828l-2 2-2.828-2.828 2-2a2 2 0 012.828 0zM2.828 14l7.086-7.086-2.828-2.828L0 11.172V14h2.828z" fill="#5477A9" />
                    </svg>
                  </span>
                </div>
              </el-option>
            </template>
          </el-select>
          <!-- Иконка - когда применена фильтрация из мониторинга / When filter from monitoring ON, show icon -->
          <div v-if="isOnlyUnits" class="flex relative cursor-pointer">
            <FilterFromMonitoring class="mt-2 ml-2" :isShowAllUnits="isShowAllUnits" @update:isShowAllUnits="isShowAllUnits = $event" />
          </div>

          <!-- Попап - Применена фильтрация из мониторинга / Popup - Have filter from monitoring -->
          <div class="absolute bg-white px-2 py-4 pl-3 tooltip-warning" v-if="isShowAllUnits">
            <div class="text-darkblue font-SourceSansPro text-sm font-semibold">
              <div class="flex mb-2">
                <FilterFromMonitoring class="mt-2 mr-2" />
                <div class="flex flex-col">
                  <span>{{ $t('reports.applied_filter') }}</span>
                  <span>{{ $t('reports.not_all_objects_displayed') }}</span>
                </div>
              </div>

              <!-- Чекбокс показать все обьекты / Checkbox show all objects -->
              <div class="flex items-center">
                <skif-checkbox class="mr-4 ml-1" v-model="showAllUnits" @change="toggleAllUnits" />
                <span class="text-darkblue font-SourceSansPro text-sm font-semibold">
                  {{ $t('reports.show_all_objects') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Подсказака выберете обьект! (появляется под селекутом обьектв) / Hint - select object! -->
        <div class="text-notify text-xs top-full left-0 absolute" v-if="isUnitOrUnitsgroupMissing">
          {{ reportForm.object === 'base' || reportForm.object === 'monitoring' ? $t('reports.select_object') : $('reports.select_objects_group') }}
        </div>
      </div>

      <!-- Выбор периода / Period -->
      <div class="w-full flex items-center mt-8">
        <span class="blue-text font-SourceSansPro text-sm font-semibold mr-2"> {{ $t('reports.period') }} : </span>
        <el-date-picker ref="datePickerRef" class="flex-1" style="width: 100%" v-model="reportForm.datePicker" popper-class="reportsDatepicker" type="datetimerange" range-separator="-" value-format="yyyy-MM-dd HH:mm:ss" :firstDayOfWeek="1" :clearable="false" :start-placeholder="$t('reports.not-select')" :end-placeholder="$t('reports.not-select')" :picker-options="pickerOptions" :format="dateFormat + ' HH:mm:ss'" :default-time="defaultTimeValues" @paste.native="onPaste" @focus="handlerFocus" @change="dateChange" />
        <el-popover v-if="isPeriodSet" placement="bottom" width="160" trigger="hover" class="ml-2 p-0" popper-class="info-popoverlay">
          <div class="info-container">
            <span>
              {{ $t('reports.have-period-from-report') }}
            </span>
          </div>

          <div slot="reference">
            <InfoIconBlue />
          </div>
        </el-popover>
      </div>

      <!-- Блоки с периодами / Block with period -->
      <div class="period flex ml-16 mt-2 justify-between mr-1">
        <div @click="setPeriod('today')" class="period-item">
          <span>
            {{ $t('reports.today') }}
          </span>
        </div>
        <div @click="setPeriod('yesterday')" class="period-item">
          <span>
            {{ $t('reports.yesterday') }}
          </span>
        </div>
        <div @click="setPeriod('this_week')" class="period-item">
          <span>
            {{ $t('reports.week') }}
          </span>
        </div>
        <div @click="setPeriod('this_month')" class="period-item">
          <span>
            {{ $t('reports.month') }}
          </span>
        </div>
      </div>
      <el-dialog :modalAppendToBody="false" append-to-body :title="$t('reports.template_deletion')" custom-class="delete_template" center="center" width="40%" :visible.sync="centerDialogVisible">
        <div class="mb-4" style="color: #5477a9; white-space: break-spaces; text-align: center">
          {{ $t('reports.template_deletion_sure') }}
          <b class="boldText"> {{ objectDeleteTemplate.name }} </b> ?
        </div>

        <!-- Модальное окно - Удаление щаблона / Modal view - Template delete -->
        <span class="dialog-footer" style="display: flex; justify-content: center">
          <span class="mr-4 cursor-pointer" @click="centerDialogVisible = false">
            {{ $t('cancel') }}
          </span>
          <skif-button class="btn cursor-pointer" @click.stop="deleteTemplate(objectDeleteTemplate)">
            {{ $t('delete') }}
          </skif-button>
        </span>
      </el-dialog>
    </div>

    <!-- Список отчетов / List reports -->
    <div class="col-start-1 col-end-3 overflow-y-auto my-2 flex flex-col w-full">
      <div class="flex w-full items-center justify-between sticky top-0 bg-white z-10">
        <!-- Выбрать все отчеты - чекбокс / Select all reports - checkbox -->
        <div class="pb-2 z-10 flex bg-white">
          <skif-checkbox class="p-2" v-model="printAllTables" @change="togglePrintAll" :indeterminate="printIndeterminate" :disabled="!tablesForTemplate.length" />
          <div class="pt-2 bg-white font-bold text-darkblue text-lg font-SourceSansPro">
            {{ $t('reports.tables') }}
          </div>
        </div>

        <!-- Загрузить выбранные отчеты / Download selected report -->
        <div class="pb-2 z-10 flex font-bold text-darkblue text-lg font-SourceSansPro">
          <el-dropdown :disabled="!printLink" trigger="click" @command="downloadAllReports">
            <div class="skif-load-link" :class="[{ disabled: !printLink }, { 'cursor-pointer': printLink }]">
              <span> {{ $t('reports.download_selected') }} </span>
              <LoadIcon :print-link="printLink" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="excel"> {{ $t('save') }} Excel </el-dropdown-item>
                <el-dropdown-item command="word"> {{ $t('save') }} Word </el-dropdown-item>
                <el-dropdown-item command="pdf"> {{ $t('save') }} PDF </el-dropdown-item>

                <el-dropdown-item command="composed">
                  {{ $t('reports.composite_report') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>

      <!-- Поиск по таблицам / Search by report section -->
      <div class="mb-2 pb-2 flex flex-col justify-center items-center searching-container bg-white z-10">
        <skif-search
          v-model="reportString"
          :placeholder="$t('reports.table.placeholder')"
          class="w-full"
          @searching="searchingReportTable"
        />
      </div>
      <table class="w-full">
        <tbody class="border-t">
          <!-- Строка таблицы / Row table -->
          <tr class="cursor-pointer lighter-border-b hover_bg-reallylightblue" v-for="(table, index) in filteredTablesForTemplate" :key="index" @click="showReport(table)">
            <td class="w-3">
              <skif-checkbox v-if="!table.is_separately" class="p-2" v-model="printTablesObj[table.id]" @click.native.stop @change="togglePrint(table.id, $event)" />
            </td>

            <CellReportMain :report="table" :report-errors="reportErrors" :report-form="reportForm" @download-separately="downloadSeparately" />
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Архив состояний -->
    <StateReport v-if="isWrongStateInclude" :units="unitsGroupWithStates" :dates="reportForm.datePicker" @refetchReport="handleNewReport" @close="isWrongStateInclude = false" :tableName="currentItem.name" />
    <!-- Составной отчет -->
    <ComposeReport v-if="isComposedReport" :isReport="isReport" :formData="reportComposeData" @handlePrint="handlePrint" @close="isComposedReport = false" :tableId="reportForm.tableType" />
  </div>
</template>

<script>
import leafletImage from 'leaflet-image'
import axios from 'axios'
import moment from 'moment-timezone'
import mapForReports from '@/mixins/mapForReports'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { selectPeriodReport, defaultValueDate, setReportFormLocalStorage, getLast24Hour, createMapImage, MONITORING, BASE, GROUP, DEMO_UNIT_ID, DEMO_DEFAULT_DATE, DEMO_REPORT_NAME } from './service/service-report'

export default {
  name: 'ReportsMain',
  components: {
    StateReport: () => import('./components/unitsWithStates/StateReport.vue'),
    ComposeReport: () => import('./ComposeReport.vue'),
    LoadIcon: () => import('@/components/reports/icons/LoadIcon.vue'),
    MapPointIcon: () => import('@/components/reports/icons/MapPointIcon.vue'),
    AddIcon: () => import('@/components/reports/icons/AddIconNoBg.vue'),
    EditIcon: () => import('@/components/reports/icons/Edit.vue'),
    CancelBigIcon: () => import('@/components/reports/icons/CancelBigIcon.vue'),
    InfoIconBlue: () => import('@/components/reports/icons/InfoIconBlue.vue'),
    FilterFromMonitoring: () => import('@/components/reports/icons/FilterFromMonitoring'),
    CellReportMain: () => import('./components/CellReportMain.vue')
  },
  mixins: [mapForReports],
  props: {
    monitoringunit: {
      type: Object,
      default: () => {}
    },
    map: {
      type: Object,
      default: () => {}
    },
    fromMonitoring: {
      type: Boolean,
      default: false
    },
    isComposeReport: {
      type: Boolean,
      default: false
    },
    isReport: {
      type: Boolean,
      default: false
    },
    isRefreshReport: {
      type: Boolean,
      default: false
    },
    isDemo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      unitsGroupWithStates: [],
      groupUnits: false,
      currentUnit: null,
      chartIndex: 0,
      defaultTimeValues: ['00:00:00', '00:00:00'],
      isComposedReport: false,
      isWrongStateInclude: false,
      refetchCount: false,
      company_id: '',
      user_id: '',
      isOnlyUnits: false,
      isFilterExist: false,
      // Фильтрация из мониторинга
      showAllUnits: false,
      isShowAllUnits: false,
      currentFilters: [],
      filtredUnits: [],
      isFilterMonitoring: false,
      centerDialogVisible: false,
      isAll: false,
      reportForm: {
        tableType: '',
        object: 'base',
        unitsId: '',
        datePicker: ['', '']
        // unitsName: ''
      },
      reportErrors: [],
      isUnitOrUnitsgroupMissing: false,
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      company: '',
      printTables: [],
      printTablesObj: {},
      printAllTables: false,
      customUnits: null,
      filterUnits: null,
      tracksdone: null,
      separetedReportLink: null,
      objectDeleteTemplate: {},
      reportComposeData: {},
      isPeriodSet: false,
      reportString: '',
      isStatesExist: false,
      currentItem: null,
      isRefetch: false,
      rowError: []
    }
  },

  computed: {
    ...mapState('reports', {
      reportTemplates: state => state.templates,
      currentReport: state => state.currentReport,
      drawenArrows: state => state.drawenArrows,
      drawenMakers: state => state.drawenMakers,
      mapToUse: state => state.mapToUse,
      chartBase64: state => state.chartBase64,
      recoveryId: state => state.recoveryId,
      newChartPeriod: state => state.isPeriodSet
    }),
    ...mapGetters('login', ['activeCompanyId', 'changingCompany', 'role', 'me']),

    ...mapGetters({
      groupTrackReport: 'reports/groupTrackReport',
      defaultReportTemplate: 'reports/defaultReportTemplate',
      formatTime: 'company/formatTime',
      unitsCount: 'archive/getUnitsCount'
    }),
    ...mapState('units', {
      units: state => state.units,
      unitGroups: state => state.unitsGroups,
      defaultUnits: state => state.defaultUnits
    }),
    ...mapState('login', {
      me: state => state.me,
      formattedCurrentTime: state => state.formattedCurrentTime
    }),

    ...mapState('properties', {
      timezoneMapping: state => state.timezoneMapping
    }),

    renderTableType() {
      return !!(this.reportForm.tableType && this.reportTemplates.length > 0)
    },

    /**
     * Возвращает массив сортированнх обьектов
     * @return {Array}
     */
    sortedUnits() {
      return this.customUnits ? [...this.customUnits].sort((a, b) => a.name.toLowerCase().trim().localeCompare(b.name.toLowerCase().trim())) : []
    },

    /**
     * Пока не понятно что это
     * @return {Boolean}
     */

    printIndeterminate() {
      return this.printTables.length > 0 && this.printTables.length !== this.tablesForTemplate.length
    },

    /**
     * Вовзращает массив шаблонов отчета
     * @return {Array}
     */
    tablesForTemplate() {
      if (this.reportTemplates.length) {
        const template = this.reportTemplates.find(item => item.id === this.reportForm.tableType)
        return template ? template?.template_tables : this.defaultReportTemplate?.template_tables
      } else {
        return []
      }
    },

    reportTables() {
      return this.$parent.reportTables
    },

    /** Возвращает отчет для скачивания
     * @return {Object}
     */
    printLink() {
      if (this.printTables.length === 0) {
        return false
      }

      const params = {
        template_tables: this.printTables.map(item => ({ id: item })),
        from: this.reportForm.datePicker ? this.reportForm.datePicker[0] : undefined,
        to: this.reportForm.datePicker ? this.reportForm.datePicker[1] : undefined
      }

      const key = this.reportForm.object === 'group' ? 'groups' : 'units'
      params[key] = [{ id: this.reportForm.unitsId }]

      return params
    },
    last24Hours() {
      const from = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const to = moment().add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
      return {
        from,
        to
      }
    },
    /**
     * Данные из мониторинда для фильтра
     * @return {Boolean}
     */

    isFromMonitoringOption() {
      const filters = JSON.parse(localStorage.getItem(`filtredUnits_${this.company_id}`))

      return filters?.filter && this.reportForm.object === MONITORING
    },

    /**
     * Возвращаем отфильтрованный массив отчетов по имени
     * @return {Array}
     */
    filteredTablesForTemplate() {
      return this.tablesForTemplate.filter(table => table.name.toLowerCase().includes(this.reportString.toLowerCase()))
    },

    /**
     * Настройки для датапикера
     * @return {Object}
     */
    pickerOptions() {
      return {
        firstDayOfWeek: 1,
        cellClassName: this.getCellClassName
      }
    },
    /**
     * Возврщает ключ подсказки
     * @return {String}
     */
    selectedObjectTextHint() {
      const singleObjectTypes = [BASE, MONITORING]
      const key = singleObjectTypes.includes(this.reportForm.object) ? 'reports.select_object' : 'reports.select_objects_group'
      return this.$t(key)
    }
  },

  watch: {
    reportTemplates(val) {
      if (val.length === 2) {
        this.reportForm.tableType = val[1].id
      } else if (val.length === 1) {
        this.reportForm.tableType = val[0].id
      }
    },
    'reportForm.tableType': {
      handler(newValue) {
        if (this.reportTemplates.length > 1 && newValue === this.defaultReportTemplate.id) {
          this.reportForm.tableType = this.reportTemplates[1].id
          this.$forceUpdate()
        }
      }
    }
  },

  methods: {
    ...mapActions({
      getFastGroupReport: 'reports/getFastTrackGroupsReport',
      getUnitsStatesCount: 'archive/getUnitsStatesCount'
    }),
    ...mapMutations('units', ['SHOW_UNITSGROUP', 'SET_UNITS']),
    ...mapMutations('reports', ['SHOW_REPORT_CHART', 'CLOSED_CALL_DRAW', 'setTableType', 'SET_TRACK']),
    ...mapMutations('archive', ['setUnitsCount']),

    handleNewReport() {
      this.isRefetch = true
      if (Array.isArray(this.unitsGroupWithStates)) {
        this.unitsGroupWithStates = this.unitsGroupWithStates.filter(unit => !unit.hasState)
      } else {
        console.error('unitsGroupWithStates is not an array')
      }
      this.showReport(this.currentItem)
    },
    getCellClassName(date) {
      const currentUnit = this.customUnits.find(unit => unit.id === this.reportForm.unitsId)
      if (!currentUnit) {
        return ''
      }
      const specificDates = currentUnit.states.map(state => state.date_from)
      const formattedDate = moment(date).format('YYYY-MM-DD')
      if (specificDates.some(specificDate => formattedDate === specificDate)) {
        this.isStatesExist = true
        return 'isDateDisabled '
      }
      this.isStatesExist = false
      return ''
    },
    handlerFocus() {
      const datepicker = this.$refs.datePickerRef
      if (datepicker) {
        setTimeout(() => {
          const reportsDatepicker = document.querySelector('.reportsDatepicker')
          reportsDatepicker.addEventListener('click', this.handler)
          document.addEventListener('keydown', this.handlerEnter)
        }, 500)
      }
      this.preserveTime()
    },

    handlerBlur() {
      this.preserveTime()
    },

    /**
     * Обрабатывает изменение даты и сохраняет состояние формы отчета в localStorage.
     *
     * @param {Array} - Новый диапазон дат в формате 'YYYY-MM-DD HH:mm:ss'.
     */
    dateChange(value) {
      if (!value) return
      this.preserveTime()
      this.isPeriodSet = false
      this.reportForm.datePicker = value
      this.preserveTime()
      const anotherFormat = '00:00:00'

      localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(this.reportForm))

      const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))

      if (formattedTime === '23:59:59' && value[1].includes(anotherFormat)) {
        const endDate = this.reportForm.datePicker[1]
        const momentDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss')
        momentDate.set({ hour: 23, minute: 59, second: 59 })
        this.reportForm.datePicker[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')

        localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(this.reportForm))
      }
      // this.preserveTime()
      // this.cacheReportForm()
    },
    preserveTime() {
      const fullArrTime = this.reportForm.datePicker.map(item => {
        const currentTimeArr = item.split(' ')
        return currentTimeArr[1] // Extract the time part
      })
      this.defaultTimeValues = fullArrTime // Update default times

      localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(this.reportForm))
    },

    handler(event) {
      if (event.key === 'Enter') {
        const datepicker = this.$refs.datePickerRef
        datepicker.handleClose()
      }
    },
    handlerEnter(e) {
      if (e.key === 'Enter') {
        const btn = document.querySelector('.el-button--mini.is-plain')
        const event = new Event('click')
        btn.dispatchEvent(event)
      }
    },

    /**
     * Записываем в свойтсво значение из инпута
     * @param {String} val - строка - значение из инпута
     */
    searchingReportTable(val) {
      this.reportString = val
    },

    /**
     * Период для отчета
     * @param {String} period - Период, для которого нужно установить диапазон дат
     */
    setPeriod(period) {
      // Извлекаем временную зону из настроек пользователя и находим соответствующую IANA зону
      const timezoneKey = this.me.active_company?.timezone?.key || 'UTC'
      const timezone = this.timezoneMapping[timezoneKey] || 'UTC' // По умолчанию используем 'UTC'

      // Определяем текущую дату на основе this.formatTime и применяем временную зону
      let now = moment().tz(timezone).endOf('day')

      if (this.me.active_company?.formatTime === '00:00:00') {
        now = now.startOf('day').add(1, 'day')
      }

      let startDate
      let endDate

      switch (period) {
        case 'today':
          startDate = moment().tz(timezone).startOf('day')
          endDate = now.format('YYYY-MM-DD HH:mm:ss')
          break

        case 'yesterday':
          startDate = moment().tz(timezone).subtract(1, 'days').startOf('day')

          if (this.me.active_company?.formatTime === '00:00:00') {
            endDate = moment().tz(timezone).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          } else {
            endDate = moment().tz(timezone).subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
          }
          break

        case 'this_week':
          startDate = moment().tz(timezone).startOf('isoWeek')
          endDate = now.format('YYYY-MM-DD HH:mm:ss')
          break

        case 'this_month':
          startDate = moment().tz(timezone).startOf('month')
          endDate = now.format('YYYY-MM-DD HH:mm:ss')
          break

        default:
          throw new Error(`Неправильный период: ${period}`)
      }

      let formattedStartDate = startDate.format('YYYY-MM-DD HH:mm:ss')
      let formattedEndDate = endDate

      // Форматируем даты в временной зоне пользователя
      this.reportForm.datePicker = [moment.tz(formattedStartDate, timezone).format('YYYY-MM-DD HH:mm:ss'), moment.tz(formattedEndDate, timezone).format('YYYY-MM-DD HH:mm:ss')]
    },

    shouldShowOption(template) {
      return (this.reportTemplates.length > 0 && !template.is_default) || (this.reportTemplates.length === 1 && template.is_default)
    },

    /**
     * Создание изображения
     */

    doImage() {
      const map = this.$parent.$refs['leaflet-map']

      const component = this
      if (component.drawenMakers && component.drawenMakers.length > 0) {
        Object.keys(component.drawenMakers).forEach(marker => {
          const drawenMarker = component.drawenMakers[marker]
          if (map.map.hasLayer(drawenMarker)) {
            map.map.removeLayer(drawenMarker)
            setTimeout(() => {
              map.map.addLayer(drawenMarker)
            }, 1000)
          }
        })
      }
      if (component.drawenArrows && component.drawenArrows.reports) {
        component.drawenArrows.reports.eachLayer(arrow => {
          arrow.removeFrom(map.map)
          setTimeout(() => {
            arrow.removeFrom(map.map)
          }, 1000)
        })
      }

      return new Promise((resolve, reject) => {
        leafletImage(map.map, (err, canvas) => {
          if (err) reject(err)
          canvas.toBlob(blob => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => resolve(reader.result.split(',')[1])
          })
        })
      })
    },
    async handlePrint(params) {
      try {
        const mapIimageBase64 = await this.doImage()
        const formData = {}
        const files = []
        if (params.isPrintMap) {
          files.push(mapIimageBase64)
        }
        if (params.isChart) {
          const imgUri = this.chartBase64.imgURI
          const chartBase64Data = imgUri.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
          files.push(chartBase64Data)
        }
        formData.files = files
        if (params.formData.units) {
          formData.units = params.formData.units
        }
        if (params.formData.groups) {
          formData.groups = params.formData.groups
        }
        formData.template_tables = params.formData.template_tables
        formData.from = params.formData.from
        formData.to = params.formData.to
        this.downloadReports(params.format, formData)
      } catch (err) {
        console.error(err)
      }
    },

    openDialogDeleteTemplate(template) {
      this.centerDialogVisible = true
      this.objectDeleteTemplate = template
    },

    async downloadSeparately({table, type}) {
      const params = {
        template_tables: [{ id: table.id }],
        from: this.reportForm.datePicker[0],
        to: this.reportForm.datePicker[1]
      }
      params[this.reportForm.object === 'group' ? 'groups' : 'units'] = [{ id: this.reportForm.unitsId }]
      const link = `/api_v1/report_${type}`
      axios
        .post(link, params, {
          responseType: 'blob'
        })
        .then(response => {
          // create a URL object from the response data
          const url = window.URL.createObjectURL(new Blob([response.data]))

          const disposition = response.headers['content-disposition']
          const regex = /utf-8''(.+)/;
          const match = disposition.match(regex);
          const filename = (match[1] || 'all_reports').trim()

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', decodeURIComponent(filename))
          document.body.appendChild(link)
          link.click()

          link.parentNode.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(async error => {
          const responseObj = await error.response.data.text()
          const errorMessage = JSON.parse(responseObj)
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: errorMessage.message
          })
        })
    },

    handleClose() {
      this.reportForm.isFromMonitoring = false
      this.$emit('close')
    },
    toggleAllUnits() {
      if (this.showAllUnits) {
        this.customUnits = this.defaultUnits
      } else {
        this.customUnits = this.filterUnits
      }
      this.reportForm.unitsId = this.customUnits[0].id
    },
    printFilterTable(table) {
      return !(table.key && ['chart', 'notifications', 'journal', 'messages'].includes(table.key))
    },

    async downloadAllReports(type) {
      if (await this.checkStates()) {
        return
      }
      if (type === 'composed') {
        const currentUnits = this.units.filter(unit => {
          if (this.printLink.units) {
            this.printLink.units.some(item => item.id === unit.id)
          } else {
            this.printLink.groups.some(item => item.id === unit.id)
          }
        })
        const currentTablesTemplate = this.printLink.template_tables
        const currenttables = this.tablesForTemplate.filter(tableTemplate => currentTablesTemplate.some(item => item.id === tableTemplate.id))

        // get the data from th ecomponent then ask for a report with a print
        this.reportComposeData = {
          currentUnits,
          currenttables,
          ...this.printLink
        }

        if (this.reportForm.object === 'group') {
          if (!this.refetchCount) {
            const formData = {
              from: this.printLink.from,
              to: this.printLink.to,
              groups: [{ id: this.reportForm.unitsId }]
            }
            await this.getUnitsStatesCount(formData)
          }
          if (this.unitsCount) {
            const counts = this.unitsCount.map(unit => Object.values(Object.values(unit))[0])
            if (counts.find(item => item > 1)) {
              this.isWrongStateInclude = true
            } else {
              this.isComposedReport = true
            }
          } else {
            this.isComposedReport = true
          }
        } else {
          this.isComposedReport = true
        }
      } else {
        this.downloadReports(type, this.printLink)
      }
    },
    async downloadReports(type, formData) {
      if (await this.checkStates()) {
        return
      }
      const link = `/api_v1/report_${type}`
      axios
        .post(link, formData, {
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))

          const disposition = response.headers['content-disposition']
          const regex = /utf-8''(.+)/;
          const match = disposition.match(regex);
          const filename = (match[1] || 'all_reports').trim()

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', decodeURIComponent(filename))
          document.body.appendChild(link)
          link.click()

          link.parentNode.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(async error => {
          const responseObj = await error.response.data.text()
          const errorMessage = JSON.parse(responseObj)
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: errorMessage.message
          })
        })
    },
    resetPrints() {
      this.printTables = []
      this.printTablesObj = {}
      this.printAllTables = false
    },
    togglePrint(key, $event) {
      if ($event.target.checked) {
        this.printTables.push(key)
      } else {
        this.printTables = this.printTables.filter(item => item !== key)
      }
    },
    togglePrintAll() {
      this.printTables = []
      const all = this.printAllTables
      this.tablesForTemplate.forEach(table => {
        if (this.printFilterTable(table)) {
          this.printTablesObj[table.id] = all
          if (all) {
            this.printTables.push(table.id)
          }
        }
      })
    },
    objectChange($event) {
      if (this.defaultUnits.length) {
        this.isUnitOrUnitsgroupMissing = false
        if ($event.target.value === 'base' && this.isFilterExist) {
          this.isFilterMonitoring = false
          this.isOnlyUnits = true
          if (this.showAllUnits) {
            this.reportForm.unitsId = this.defaultUnits[0].id
          } else {
            this.reportForm.unitsId = this.customUnits[0].id
          }
          // this.reportForm.unitsId = this.units.length ? this.units[0].id : ''
        } else if ($event.target.value === 'base') {
          this.reportForm.unitsId = this.defaultUnits[0].id
          this.isFilterMonitoring = false
        } else if ($event.target.value === 'group') {
          this.isShowAllUnits = false
          this.isFilterMonitoring = false
          this.isOnlyUnits = false
          this.reportForm.unitsId = this.unitGroups.length ? this.unitGroups[0].id : ''
          this.groupUnits = true
        } else if ($event.target.value === 'monitoring') {
          this.isFilterMonitoring = true
          this.isShowAllUnits = false
        }
      }
      // this.cacheReportForm()
    },
    tableTypeChange() {
      this.resetPrints()
      localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(this.reportForm))
    },
    async checkStates() {
      const { object, unitsId } = this.reportForm
      let units
      if (object === 'group') {
        if (this.isRefetch) {
          units = this.unitsGroupWithStates.filter(unit => !unit.hasStates)
        } else {
          units = this.unitGroups.find(group => group.id === unitsId).units
        }
        return await this.hasStatesForUnitGroup(units)
      } else {
        return await this.hasStates(unitsId)
      }
    },
    async unitsNameChange(unit) {
      this.reportForm.unitsId = unit
      this.refetchCount = false
      localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(this.reportForm))
    },

    cacheReportForm() {
      if (process.env.NODE_ENV === 'production') {
        let storageReportForm = localStorage.getItem(`reportsForm${this.user_id}${this.company_id}`)

        storageReportForm = storageReportForm ? JSON.parse(storageReportForm) : {}
        storageReportForm[this.activeCompanyId] = this.reportForm

        localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(storageReportForm))
      }
    },
    async hasStatesForUnitGroup(group) {
      if (!group) {
        this.isWrongStateInclude = false
        return false
      }

      this.unitsGroupWithStates = []

      for (const unit of group) {
        const hasStates = await this.hasStates(unit.id)

        this.unitsGroupWithStates.push({ ...unit, hasStates })
      }
      this.unitsGroupWithStates.sort((a, b) => b.hasStates - a.hasStates)

      this.isWrongStateInclude = this.unitsGroupWithStates.some(unit => unit.hasStates)
      return this.isWrongStateInclude
    },
    /**
     * Checks if the given unit has any states within a specified date range that are less than 10 years old.*
     * @param {number} unitId - The ID of the unit to check.
     */
    async hasStates(unitId) {
      const unit = this.customUnits.find(unit => unit.id === unitId)

      try {
        // Early return if no unit or no states
        if (!unit || (unit.states && unit.states.length === 0) || (unit.states && unit.states.length === 1)) {
          return false
        }

        const [startDate, endDate] = this.reportForm.datePicker.map(date => moment(date))
        const currentDate = moment()
        if (unit?.states) {
          for (const state of unit.states) {
            const stateDate = moment(state.date_from)

            // если архивное состояние начинается с даты from в datepicker
            if (moment(startDate).format('YYYY-MM-DD') === state.date_from) {
              return false
            }

            const yearsDifference = currentDate.diff(stateDate, 'years')
            // Check if state date is within the specified range and less than 10 years old
            if (stateDate.isBetween(startDate, endDate, undefined, '[]') && yearsDifference < 10) {
              await this.showStatesErrorNotification([state])
              return true
            }
          }
        }

        return false
      } catch (e) {
        console.error(e)
      }
    },

    async showStatesErrorNotification(states) {
      if (this.reportForm.object === 'group') {
        return
      }

      const stateDetails = states
        .filter(state => {
          const stateDate = moment(state.date_from)
          const yearsDifference = moment().diff(stateDate, 'years')
          return yearsDifference <= 10
        })
        .map(state => `<strong>${state.date_from}</strong> (${state.name})`)
        .join(', ')

      if (stateDetails.length === 0) {
        return // No states within the limit to show
      }

      await this.$notify.error({
        duration: 5000,
        title: `${this.$t('error')} \u{1f62a}`,
        message: `${this.$t('archives.object.haveState')}: ${stateDetails}.`,
        dangerouslyUseHTMLString: true
      })
    },

    setOpenedReportsInStorage(item) {
      let openedReports = JSON.parse(localStorage.getItem('openedReports'))

      if (!openedReports) {
        openedReports = []
      }
      const currentUnits = this.units.filter(unit => this.reportForm.unitsId === unit.id)
      if (item && item.key && !openedReports.includes(item.key)) {
        openedReports.push({
          name: item.name,
          key: item.key,
          unit: currentUnits[0],
          id: item.id,
          time_group: item.time_group || '',
          closed_to_change: item.closed_to_change
        })
      }
      this.setTableType(item.key)
      localStorage.setItem('openedReports', JSON.stringify(openedReports))
    },

    /**
     * Main function to show report based on the provided item .
     * item contain report details.
     */
    async showReport(item) {
      try {
        this.currentItem = item
        if (await this.checkStates()) {
          return
        }

        const isGroupObject = this.reportForm.object === 'group'
        const isBaseObject = this.reportForm.object === 'base'
        const isMonitoringObject = this.reportForm.object === 'monitoring'
        const isChart = item.key === 'chart'

        this.setOpenedReportsInStorage(item)

        if (isGroupObject && isChart) return
        /** if its objects group we don't show any tracks or legend EXCEPT IF fields_processing       */
        if (isGroupObject && item.key === 'fields_processing') {
          this.$store.commit('reports/setGroupOrNormalReport', 'group')
          await this.processGroupReport(item)
          this.handleReportErrors(item, isChart, isBaseObject, isMonitoringObject)
        } else {
          this.$store.commit('reports/setGroupOrNormalReport', 'normal')
          const filters = JSON.parse(localStorage.getItem(`filtredUnits_${this.company_id}`))
          const options = {
            item,
            isChart,
            isBaseObject,
            isMonitoringObject,
            filters
          }

          this.processOtherReports(options)
        }

        this.updateCanvasStyles()
      } catch (error) {
        console.error('Error in showReport:', error)
      }
    },

    /**
     * Processes and shows group report + TRACK -Обработка полей,
     */

    async processGroupReport(item) {
      try {
        const dataReport = {
          groups: [{ id: this.reportForm.unitsId }],
          from: this.reportForm.datePicker[0],
          to: this.reportForm.datePicker[1],
          zoom: 14
        }

        const response = await this.getFastGroupReport(dataReport)
        const { data } = response

        // const styles = {};
        // const borderStyles = {};
        // const shadowStyles = {};

        // Раскраска трека по новому формату
        // const allTrack = data.flatMap(curr =>
        //   curr.values.map(value => {
        //     if (!styles[new_style_key]) styles[new_style_key] = [];
        //     this.styles[new_style_key].push({
        //       color: style ? style.hex : curr.color,
        //       weight: this.currentWeights,
        //     })

        //     if (!borderStyles[new_style_key]) borderStyles[new_style_key] = [];
        //     this.borderStyles[new_style_key].push({
        //       color: '#fff',
        //       weight: this.currentWeights + 2
        //     })

        //     if (!shadowStyles[new_style_key]) shadowStyles[new_style_key] = [];
        //     this.shadowStyles[new_style_key].push({
        //       color: 'gray',
        //       weight: this.currentWeights + 3,
        //       opacity: 0.4,
        //       filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16))'
        //     });

        //     const style = curr.legenda.newStyles.find(style => style.key === value.new_style_key);
        //     return {
        //       ...value,
        //       unit_id: curr.unit_id,
        //       color: style ? style.hex : curr.color
        //     };
        //   })
        // );

        // const allTrack = data.flatMap(curr =>
        //   curr.values.map(value => {
        //     console.log(value, 'value');
        //     const style = curr.legenda.newStyles.find(style => style.key === value.new_style_key);

        //     if (!this.styles[value.new_style_key]) this.styles[value.new_style_key] = []
        //     this.styles[value.new_style_key].push({
        //       color: style ? style.hex : curr.color,
        //       weight: this.currentWeights,
        //     })

        //     if (!this.borderStyles[value.new_style_key]) this.borderStyles[value.new_style_key] = []
        //     this.borderStyles[value.new_style_key].push({
        //       color: '#fff',
        //       weight: this.currentWeights + 2
        //     })

        //     if (!this.shadowStyles[value.new_style_key]) this.shadowStyles[value.new_style_key] = []
        //     this.shadowStyles[value.new_style_key].push({
        //       color: 'gray',
        //       weight: this.currentWeights + 3,
        //       opacity: 0.4,
        //       filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16))'
        //     })

        //     return {
        //       ...value,
        //       unit_id: curr.unit_id,
        //       color: style ? style.hex : curr.color
        //     };
        //   })
        // )

        // data.forEach(curr => {
        //   curr.values.forEach(value => {
        //     const style = curr.legenda.newStyles.find(style => style.key === value.new_style_key)
        //     const key = value.new_style_key

        //     // Initialize and populate styles
        //     if (!this.styles[key]) {
        //       this.styles[key] = {
        //         color: style ? style.hex : curr.color,
        //         weight: this.currentWeights
        //       }
        //     }

        //     // Initialize and populate borderStyles
        //     if (!this.borderStyles[key]) {
        //       this.borderStyles[key] = {
        //         color: '#fff',
        //         weight: this.currentWeights + 2
        //       }
        //     }

        //     // Initialize and populate shadowStyles
        //     if (!this.shadowStyles[key]) {
        //       this.shadowStyles[key] = {
        //         color: 'gray',
        //         weight: this.currentWeights + 3,
        //         opacity: 0.4,
        //         filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16))'
        //       }
        //     }
        //   })
        // })

        // Create an array of transformed values with unit_id and color
        const allTrack = data.flatMap(curr => {
          console.log(curr.color, 'curr')
          return curr.values.map(value => {
            return {
              ...value,
              unit_id: curr.unit_id,
              color: curr.color,
              shadowLayer: {
                color: 'gray',
                weight: this.currentWeights + 3,
                opacity: 0.4,
                filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16))'
              },
              borderLayer: {
                color: '#fff',
                weight: this.currentWeights + 2
              }
            }
          })
        })

        // console.log(result, 'result');

        const unitId = dataReport.groups[0].id
        const uniqueTrackId = `[${unitId}][${dataReport.from}-${dataReport.to}]`

        this.SET_TRACK([unitId, allTrack, uniqueTrackId])
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * Processes other types of reports based on the item and object types.
     * @param {Object} item - The item containing report details.
     * @param {boolean} isChart - Whether the item is a chart.
     * @param {boolean} isBaseObject - Whether the object is a base object - it can be group of units .
     * @param {boolean} isMonitoringObject - Whether the object is a monitoring object(when there is a filter active in monitoring module).
     * @param {Object} filters - The filters retrieved from local storage.
     */
    processOtherReports({ item, isChart, isBaseObject, isMonitoringObject, filters }) {
      if (!isChart) {
        this.SHOW_REPORT_CHART()
      }

      this.handleObjectReports(isBaseObject, isMonitoringObject)
      this.handleReportErrors(item, isChart, isBaseObject, isMonitoringObject)
    },

    /**
     * Handles object-specific report actions.
     * @param {boolean} isBaseObject - Whether the object is a base object.
     * @param {boolean} isMonitoringObject - Whether the object is a monitoring object(when filter is active in monitoring).
     */
    handleObjectReports(isBaseObject, isMonitoringObject) {
      if (!this.isRefetch) {
        if (isBaseObject || isMonitoringObject) {
          const unitIds = this.customUnits.map(unit => ({ id: unit.id }))

          if (this.isFromMonitoringOption) {
            this.reportForm.unitsId = unitIds
          }

          this.$store.dispatch('units/SHOW_UNITS_GROUP', {
            id: this.isFromMonitoringOption ? this.reportForm.unitsId : [{ id: this.reportForm.unitsId }],
            show: false
          })
        } else if (this.reportForm.object === 'group') {
          this.$store.dispatch('units/SHOW_UNITS_GROUP', {
            id: this.reportForm.unitsId,
            show: true
          })
        }
      }
    },

    /**
     * Handles report errors and manages report conditions.
     */
    handleReportErrors(item, isChart, isBaseObject, isMonitoringObject) {
      const reportId = isChart ? item.id : item.id + (this.isFromMonitoringOption ? this.reportForm.unitsId[0].id : this.reportForm.unitsId) + this.reportForm.datePicker.join('')

      if (this.reportForm.unitsId === '') {
        this.addReportError(reportId)
        return
      }

      if (this.reportTables.some(reportTable => reportTable.reportId === reportId) || this.reportTables.length > 3) {
        this.addReportError(reportId)
      } else {
        this.dispatchReport(item, reportId, isBaseObject, isMonitoringObject)
      }
    },
    /**
     * Adds a report error to the list of errors.
     */
    addReportError(reportId) {
      this.isUnitOrUnitsgroupMissing = this.reportForm.unitsId === ''
      this.reportErrors.push(reportId)
      setTimeout(() => {
        this.reportErrors.splice(this.reportErrors.indexOf(reportId), 1)
      }, 500)
    },
    /**
     *  Dispatches the report to the store and emits the necessary events.
     */
    dispatchReport(item, reportId, isBaseObject, isMonitoringObject) {
      this.CLOSED_CALL_DRAW(false)
      let from, to
      if (item.key === 'messages') {
        from = this.last24Hours.from
        to = this.last24Hours.to
      } else {
        from = this.reportForm.datePicker[0]
        to = this.reportForm.datePicker[1]
      }
      const filters = JSON.parse(localStorage.getItem(`filtredUnits_${this.company_id}`))
      this.$nextTick(() => {
        let units
        if (this.isRefetch) {
          units = this.unitsGroupWithStates
        } else if (this.isFromMonitoringOption) {
          units = filters.units.map(item => ({ id: item }))
        } else {
          units = [{ id: this.reportForm.unitsId }]
        }

        if (this.reportForm.object === 'base' || this.reportForm.object === 'monitoring') {
          this.$store.dispatch('reports/GET_TRACK', {
            zoom: 14,
            units: units,
            from,
            to
          })
        }
      })

      item.tableType = item.key === 'chart' ? 'reportChart' : 'reportTable'
      this.$store.commit('reports/SET_LOADING', [reportId, true])

      let itemOrGroup = isBaseObject || isMonitoringObject ? 'units' : 'groups'

      if (this.isRefetch) {
        itemOrGroup = 'groups'
      }

      let idType
      if (this.isFromMonitoringOption) {
        idType = filters.units.map(item => ({ id: item }))
      } else if (this.reportForm.object === 'group') {
        idType = [{ id: this.reportForm.unitsId }]
      } else {
        idType = [{ id: this.reportForm.unitsId }]
      }

      const baseFormData = {
        [itemOrGroup]: idType,
        template_tables: [{ id: item.id }],
        from: this.reportForm.datePicker[0],
        to: this.reportForm.datePicker[1]
      }

      if (this.isAll && item.key === 'notifications') {
        this.emitShowReport(item, reportId, baseFormData)
      } else {
        this.emitShowReport(item, reportId, baseFormData, false)
      }
    },
    /**
     * Emits the 'show-report' event with the necessary data.
     * the proccess  :
     *  1- dispatchReport() will prepare the data object and decide if its a group or units/monitoringfilter/refetch new report with units without archive states"
     *  2- emitShowReport() will emit the 'show-report' event with the necessary data
     * 3- showReport() will be called from main.vue(parent component of reports.vue )
     * 4- showReport() is another function in newReports.js in mixins
     */
    emitShowReport(item, reportId, baseFormData, isAll = true) {
      const { id: tableId, time_group, closed_to_change } = item

      const reportFormData = {
        ...baseFormData,
        isAll,
        time_group: time_group || '',
        tableId,
        closed_to_change
      }

      this.$emit('show-report', {
        reportFormData,
        tableInfo: { ...item, reportId },
        isZoomed: false
      })
      this.isRefetch = false
    },
    /**
     * Updates the z-index of canvas elements based on the browser.
     */
    updateCanvasStyles() {
      const canv = document.getElementsByTagName('canvas')

      if (canv.length > 1) {
        if (navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('Firefox') !== -1) {
          canv[0].style.zIndex = '101'
          canv[1].style.zIndex = '100'
        }
      }
    },

    defaultValueDate() {
      const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))
      let startDate = ''
      let endDate = ''
      if (formattedTime === '23:59:59') {
        startDate = moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss')
        endDate = moment(startDate).set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DD HH:mm:ss')

        return [startDate, endDate]
      }
      startDate = moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss')
      endDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
      return [startDate, endDate]
    },
    onPaste(event) {
      const datesMatch = event.clipboardData.getData('text').match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}/g)
      const dates = [`${datesMatch[0]}:00`, `${datesMatch[1]}:00`]
      if (dates.length === 2 && moment(dates[0], 'yyyy-MM-dd HH:mm:ss').isValid() && moment(dates[1], 'yyyy-MM-dd HH:mm:ss').isValid()) {
        this.reportForm.datePicker = dates
      }
    },
    // tablesForTemplate
    deleteTemplate(template) {
      this.centerDialogVisible = false
      this.$store.dispatch('reports/DELETE_TEMPLATE', template).then(() => {
        // remove template recovery for now !
        // this.showDeleteNotification(template)
        if (template.id === this.reportForm.tableType) {
          const remainingTemplates = this.reportTemplates.filter(t => t.id !== template.id)
          if (remainingTemplates.length === 1) {
            this.reportForm.tableType = this.reportTemplates[0]

            localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(this.reportForm))
          } else if (remainingTemplates.length >= 2) {
            const deletedIndex = this.reportTemplates.findIndex(t => t.id === template.id)

            const nextIndex = deletedIndex === this.reportTemplates.length - 1 ? deletedIndex - 1 : deletedIndex

            this.reportForm.tableType = remainingTemplates[nextIndex].id
          }
        }
      })
    },
    showDeleteNotification(template) {
      const self = this
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div class='confirmation-container'>
                <span>${this.$t('reports.template')} ${template.name}  ${this.$t('reports.was-deleted')} </span>
                <span id='confirm' class='cursor-pointer cancelConfirm blue-text'>${this.$t('cancel')}</span>
              </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation  '
      })

      const confirmation = document.getElementById('confirm')
      confirmation.addEventListener(
        'click',
        function () {
          self.restoreTemplate(template)
        },
        false
      )
    },
    restoreTemplate(template) {
      this.$store.dispatch('reports/RECOVER_TEMPLATE', {
        id: this.recoveryId,
        template
      })
      this.$notify.closeAll()
    },

    /**
     * Устанавливает форму отчета в локальное хранилище.
     * @param {Array} result - Результат, содержащий данные для проверки и установки формы.
     */
    setFormLocalStorage(result) {
      let storageReportForm = localStorage.getItem(`reportsForm${this.user_id}${this.company_id}`)

      storageReportForm = storageReportForm ? JSON.parse(localStorage.getItem(`reportsForm${this.user_id}${this.company_id}`)) : {}
      if (storageReportForm[this.activeCompanyId]) {
        const form = storageReportForm[this.activeCompanyId]
        const isRealId = (result[1].some(item => item.id === form.unitsId) && form.object === 'base') || (result[2].some(item => item.id === form.unitsId) && form.object === 'group')
        const isRealRTId = result[0].some(rt => rt.id === form.tableType)
        this.reportForm = {
          tableType: isRealRTId ? form.tableType : '',
          object: form.object,
          unitsId: isRealId ? form.unitsId : '',
          datePicker: form.datePicker
        }
      } else {
        storageReportForm[this.activeCompanyId] = this.reportForm = {
          tableType: result[0][0].id,
          object: 'base',
          unitsId: result[1].length ? result[1][0].id : '',
          datePicker: this.defaultValueDate()
        }

        localStorage.setItem(`reportsForm${this.user_id}${this.company_id}`, JSON.stringify(storageReportForm))
      }
    },
    isUnitExist(unit, array) {
      return array.some(element => element.id === unit)
    },

    getClassesForTableName(table) {
      const hasError = this.reportErrors.includes(table.id + this.reportForm.unitsId + this.reportForm.datePicker.join(''))
      const disableChart = this.reportForm.object === 'group' && table.key === 'chart'

      return {
        'text-notify': hasError,
        'text-headerText': !hasError,
        disabled: disableChart
      }
    },
    setDefaultUnit() {
      this.reportForm.unitsId = this.defaultUnits.length ? this.defaultUnits[0].id : ''
    },
    setDefaultTableType() {
      if (this.currentReport && this.currentReport.tableType) {
        this.reportForm.tableType = this.defaultReportTemplate.id

        return
      }

      this.reportForm.tableType = this.reportTemplates.length ? this.reportTemplates[0].id : ''
    },

    /**
     * Получает формат даты и времени из локального хранилища и устанавливает их в переменные.
     */
    getDateFormat() {
      setTimeout(() => {
        const company = this.me.active_company
        const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))

        if (formattedTime) {
          this.dateFormat = `${company.dateformat.key} ${formattedTime}`
        } else {
          this.dateFormat = company.dateformat.key
        }
        this.dateFormat = company.dateformat.key
        this.timeFormat = company.timeformat.key

        this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
      }, 100)
    },
    checkExistingUnit(unitId, type = 'base') {
      const units = type === 'base' ? this.customUnits : this.unitGroups
      const existingUnit = units.find(item => item.id === unitId)
      if (!existingUnit && units.length > 0) {
        this.reportForm.unitsId = units[0].id
      }
      this.currentUnit = existingUnit
    }
  },

  created() {
    this.setDefaultUnit()
    this.setDefaultTableType()
  },
  async mounted() {
    this.user_id = this.me.id
    this.company_id = this.me.active_company.id

    const filters = JSON.parse(localStorage.getItem(`filtredUnits_${this.company_id}`))
    const reportForm = JSON.parse(localStorage.getItem(`reportsForm${this.user_id}${this.company_id}`))

    const reportFormStorage = reportForm || {}
    this.reportForm = {
      ...this.reportForm,
      ...reportFormStorage
    }
    this.customUnits = this.defaultUnits

    const isFilterExist = !!filters && !!filters.filter

    if (!isFilterExist && this.reportForm.object === 'monitoring') this.reportForm.object = 'base'

    if (this.fromMonitoring && isFilterExist) this.reportForm.object = 'monitoring'

    this.checkExistingUnit(this.reportForm.unitsId, this.reportForm.object)

    // CHECK FILTERS
    if (isFilterExist) {
      this.showAllUnits = this.reportForm.showAllObjects

      this.customUnits = this.filterUnits = this.units.filter(unit => filters.units.includes(unit.id))

      if (this.showAllUnits) this.customUnits = this.defaultUnits

      if (this.reportForm.object === 'base') {
        this.checkExistingUnit(this.reportForm.unitsId)

        this.isOnlyUnits = true
      }

      this.isFilterExist = true
    }

    // CHECK DATEPICKER EXISTING IN STORAGE
    const currentDateLocalStorage = JSON.parse(localStorage.getItem('currentDateReport'))

    const currentDateNumber = new Date()
    const numberDate = currentDateNumber.getDate()

    if (!currentDateLocalStorage) {
      localStorage.setItem('currentDateReport', numberDate)
    }

    if (currentDateLocalStorage !== numberDate) {
      this.reportForm.datePicker = this.defaultValueDate()
      localStorage.setItem('currentDateReport', numberDate)
    }

    if (reportFormStorage && !reportFormStorage.datePicker) {
      this.reportForm.datePicker = this.defaultValueDate()
    }

    // CHECK TABLE TYPE
    if (reportFormStorage && !reportFormStorage.tableType) {
      this.setDefaultTableType()
    }

    this.getDateFormat()

    if (this.newChartPeriod.length) {
      this.reportForm.datePicker = this.newChartPeriod
      this.isPeriodSet = true
    } else {
      this.isPeriodSet = false
    }

    /**
     * For demo only
     * */
    if (this.isDemo) {
      setTimeout(() => {
        this.filteredTablesForTemplate.forEach(item => {
          if (item.name.toLowerCase() === 'поездки') {
            this.reportForm.unitsId = '6d3892de-421a-4f0e-bc13-330534fc3381'
            this.reportForm.datePicker = ['2024-04-01 00:00:00', '2024-04-14 00:00:00']
            this.showReport(item)
          }
        })
      }, 1000)
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handler)
    this.$store.dispatch('reports/SAVE_CURRENTREPORT', this.reportForm)
    this.$emit('clear-monitoringunit')

    localStorage.setItem(
      `reportsForm${this.user_id}${this.company_id}`,
      JSON.stringify({
        ...this.reportForm,
        showAllObjects: this.showAllUnits,
        groupUnit: this.reportForm.object === 'group' ? this.reportForm.unitsId : ''
      })
    )
  }
}
</script>
<style>
.isDateDisabled div {
  pointer-events: none;
  background-color: #ffb4b4 !important;
  color: white;
}
/* .isDateDisabled::before {
  content: 'Выбранный вами интервал имеет пересечения между архивными состояниями объекта';
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #666262;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 1000;
  font-weight: bold;
} */

.isDateDisabled:hover::before {
  display: block;
}
.searching-container {
  position: sticky;
  top: 45px;
}
.lighter-border-b {
  border-bottom: 1px solid #f6f7fa;
}
.info-popoverlay {
  padding: 0;
  background: #dbebff;
  width: auto !important;

  padding: 4px 8px;
  overflow: hidden;
}
.info-container {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #2767b6;
}
.period {
  align-items: center;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.period-item {
  color: #20579a;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: #dbebff;
  border-radius: 3px;
}
.period-item:hover {
  color: #fff;
  cursor: pointer;
  background: #3a7cca;
}
.blue-text {
  color: #80abe1;
}
.bg-select-object {
  background-color: #f6f7fa;
}
.select-object-container {
  padding-left: 14px;
  padding-top: 11px;
  padding-bottom: 12px;
  border-radius: 4px;
}
.confirmation {
  width: 466px;
  background-color: #eff4fb;
  margin-left: 4.5rem;

  .el-notification__group {
    margin-right: 30px;
    width: 95%;

    .el-notification__content {
      width: 95%;

      p {
        .confirmation-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .el-icon-close {
      margin-top: 3px;

      &::before {
        font-weight: bolder;
        font-size: 18px;
      }
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
<style lang="scss" scoped>
.download-separatly {
  outline: none;
}
.boldText {
  font-size: 16px;
  font-weight: bolder;
}

.delete_template {
  box-shadow: none !important;
}

.skif-load-link {
  display: flex;
  font-size: 14px;
  color: #5477a9;

  svg {
    width: 20px;
    height: 20px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.tooltip-warning {
  left: 345px;
  border: 1px solid #cfdbeb;
  bottom: 25px;
  width: 320px;
  border-radius: 5px;
}
.group-name {
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.row-reverse {
  flex-direction: row-reverse;
}

.reports-main {
  position: absolute;
  display: grid;
  width: 444px;
  left: 68px;
  z-index: 30;
  bottom: 1.25rem;
  top: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
}
</style>
